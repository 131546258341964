import it from "./locales/normal/it.json";
import en from "./locales/normal/en.json";

import itCrazy from "./locales/crazy/it.json";
import enCrazy from "./locales/crazy/en.json";

export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    it: {
      normal: {
        ...it,
      },
      crazy: {
        ...itCrazy,
      },
    },
    en: {
      normal: {
        ...en,
      },
      crazy: {
        ...enCrazy,
      },
    },
  },
}));
